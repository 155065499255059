
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

import type { Post } from "../store";

@Component({
  filters: {
    created_at(value, appLanguage) {
      if (!value) return;

      return moment(value)
        .locale(appLanguage)
        .format("Do MMMM, Y");
    },
    created_at_from_now(value, appLanguage) {
      if (!value) return;

      return moment(value)
        .locale(appLanguage)
        .fromNow();
    }
  }
})
export default class MyPostComponent extends Vue {
  @Prop(Object) readonly post!: Post;

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  get urlUpload() {
    return this.post.url_upload;
  }
}
